import React, { Component } from 'react'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import left from '../assets/images/arrow-left.svg'
import right from '../assets/images/arrow-right.svg'
import Video from './video.js'
import Div100vh from 'react-div-100vh'

function NextArrow(props) {
	const { className, style, onClick } = props
	const contProps = { className, style, onClick }
	return (
		<div {...contProps}>
			<img src={right} alt="next gallery item" />
		</div>
	)
}

function PrevArrow(props) {
	const { className, style, onClick } = props
	const contProps = { className, style, onClick }
	return (
		<div {...contProps}>
			<img src={left} alt="last gallery item" />
		</div>
	)
}

export default class SlickGallery extends Component {
	state = {
		activeSlide: 1,
		overlayClass: 'gallery-overlay',
	}

	componentDidMount() {
//		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('scroll', (event) => {
			let scrollTop = document.documentElement.scrollTop;
			if(scrollTop > 0)
			{
				this.setState({
					overlayClass: 'gallery-overlay-inactive'
				});
			}
		});
	}

	componentWillUnmount() {
//		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll(event)
	{
		let scrollTop = document.documentElement.scrollTop;
		if(scrollTop > 0)
		{
			this.setState({
				overlayClass: 'gallery-overlay-inactive'
			});
		}
	}

	render() {
		let { overlay_content, overlay_transparency, overlay_colour } = this.props

		const settings = {
			infinite: true,
			speed: 600,
			fade: true,
			autoplay: true,
			autoplaySpeed: 5000,
			slidesToShow: 1,
			swiping: true,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			cssEase: 'ease',
			pauseOnHover: false,
			focusOnSelect: false,
			afterChange: current => this.setState({ activeSlide: current + 1 }),
			nextArrow: <NextArrow />,
			prevArrow: <PrevArrow />,
		}
		return (
			<Div100vh className="slick-gallery">
				<Slider className="slick-gallery__wrapper" {...settings}>
					{this.props.slides.map((el, i) => {
						let image =
							el.image.localFile &&
							el.image.localFile.childImageSharp.original.src
						let mobileImage =
							el.mobile_image.localFile &&
							el.mobile_image.localFile.childImageSharp.original.src
						let videoProps = {
							video: el.video_id,
							poster: el.image.localFile.childImageSharp.original.src,
							autoPlay: true,
						}

						return (
							<div key={i}>
								<div
									className={this.state.overlayClass}
									style={{ opacity: el.overlay_transparency }}
								>
									<div
										className="background-transparent"
										style={{ backgroundColor: el.overlay_colour }}
									/>
								</div>
								{!el.video_id && (
									<picture className="slick-gallery__item">
										<source media="(min-width:800px)" srcSet={image} />
										<img src={mobileImage} alt={el.title} />
									</picture>
								)}
								{el.video_id && (
									<Video className="slick-gallery__video" {...videoProps} />
								)}
								<div className="slick-gallery__title">
									<Link to={el.link}>{el.title}</Link>
								</div>
							</div>
						)
					})}
				</Slider>
				<div className={this.state.overlayClass}>
					<h1
						className="overlay-content"
						dangerouslySetInnerHTML={{ __html: this.props.overlay_content }}
					/>
				</div>
				<div className="slick-gallery__meta">
					<p className="image__pager">
						{this.state.activeSlide} / {this.props.slides.length}
					</p>
				</div>
			</Div100vh>
		)
	}
}
