	import React, { Component } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import moment from 'moment'

import BlogCard from './cards/blog-card'

class LatestPosts extends Component {
	getImage(image) {
		if (image === null || !image.localFile) return
		return image.localFile.childImageSharp.fixed.src
	}

	render() {
		let { data } = this.props

		const customPosts = data.wordpressPage.acf.posts_list
		const staticPosts = data.allWordpressPost.edges
		const authorsList = data.allWordpressPost.edges;
		let counter = 0;
		return (
			<section className="latest-posts latest-posts--landing">
				<div className="latest-posts__inner container">
					<div className="col col--1of3">
						<div className="title">
							<div className="move-logo"></div>
							{/* <h2>
								{this.props.title}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="78.358"
									height="73.883"
									viewBox="0 0 78.358 73.883"
								>
									<path
										d="M24.81,0,0,1.519l4.3,60.76,32.659-2.785.506,3.038,13.925,2.785L58.988,4.56v0Z"
										transform="matrix(0.259, 0.966, -0.966, 0.259, 63.091, 0)"
										fill="#ff4a00"
									/>
								</svg>
							</h2> */}
						</div>
					</div>
					<div className="col col--2of3">
						<div className="content">
							<p>{this.props.content}</p>
							<Link to="/blog">Read the latest</Link>
						</div>
					</div>
					<div className="col col--3of3 latest-posts__items">
						{customPosts.length &&
							customPosts &&
							customPosts.map((el, i) => {
								counter++;
								if( counter > 6)
								{
									return;
								}
								let link = '';
								let parts = el.post.guid.split('/');
								let lastSegment = parts.pop() || parts.pop();
								link = '/' + el.post.post_name;
								let author_name = '';
								if( el.post.acf.author_name )
								{
									author_name = el.post.acf.author_name;
								}
								else
								{
									let post_id = el.post.wordpress_id;
									//Find it
									staticPosts.map((el, i) => {
										let this_id = el.node.wordpress_id;
										if( this_id == post_id )
										{
											author_name = el.node.author;
										}
									});
								}

								let props = {
									image: this.getImage(el.post.acf.featured_image),
									title: el.post.post_title,
									//link: `/${el.post.guid}`,
									link: link,
									author: author_name,
									date: moment(el.post.post_date).format('MMM, YYYY'),
								}
								return <BlogCard {...props} key={i} />
							})}

						{customPosts.length == 0 &&
							staticPosts &&
							staticPosts.map((el, i) => {
								counter++;
								if(counter > 6)
								{
									return;
								}
								let props = {
									image: this.getImage(el.node.acf.featured_image),
									title: el.node.title,
									link: `/${el.node.slug}`,
									author: el.node.acf.author_name
										? el.node.acf.author_name
										: el.node.author,
									date: moment(el.node.date).format('MMM, YYYY'),
								}
								return <BlogCard {...props} key={i} />
							})}
					</div>
				</div>
			</section>
		)
	}
}

export default props => (
	<StaticQuery
		query={graphql`
			query latestPostQuery {
				allWordpressPost(limit: 100, sort: { fields: [date], order: DESC }) {
					edges {
						node {
							title
							wordpress_id
							date
							slug
							author
							acf {
								author_name
								featured_image {
									localFile {
										childImageSharp {
											fixed(width: 1200) {
												src
											}
										}
									}
								}
							}
						}
					}
				}
				wordpressPage(wordpress_id: { eq: 2 }) {
					title
					acf {
						posts_list {
							post {
								post_title
								wordpress_id
								post_author
								post_date
								post_date_gmt
								post_content
								post_title
								post_excerpt
								post_status
								comment_status
								ping_status
								post_password
								post_name
								to_ping
								pinged
								post_modified
								post_modified_gmt
								post_content_filtered
								post_parent
								guid
								menu_order
								post_type
								post_mime_type
								comment_count
								filter
								acf {
									author_name
									featured_image {
										localFile {
											childImageSharp {
												fixed(width: 1200) {
													src
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`}
		render={data => <LatestPosts data={data} {...props} />}
	/>
)
