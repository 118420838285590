import React, { Component } from 'react'
import { Link } from 'gatsby'


class FeatureContent extends Component {
	state = {
		active: false,
	}

	renderImage(image) {
		if (image === null || !image.localFile) return
		return (
			<img
				src={image.localFile.childImageSharp.original.src}
				alt={image.alt_text}
			/>
		)
	}

	render() {
		let {
			image,
			paragraph_title,
			paragraph,
			link_text,
			link_path,
		} = this.props
		let { active } = this.state

		return (
			<section className="feature-content latest-posts">
			  <div className="latest-posts__inner container">
				<div className="col col--1of3 ">
					{this.renderImage(image)}
				</div>

				<div className="col col--2of3">
				    <h1 dangerouslySetInnerHTML={{__html: paragraph_title }} />
				    <div dangerouslySetInnerHTML={{__html: paragraph }} />
				    <Link to={link_path}>{link_text}</Link>
				</div>
			  </div>
			</section>
		)
	}
}

export default FeatureContent
