import React, { Component, useEffect } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'
import Logo from '../components/logo'
import SlickGallery from '../components/gallery'
import VideoContent from '../components/video-content'
import FeatureContent from '../components/feature-content'
import LatestPosts from '../components/latest-posts'
import Subscribe from '../components/subscribe'
import PageCover from '../components/page-cover'
import { Helmet } from 'react-helmet'

function setCookie(name, value, days) {
	let expires = ''
	if (days) {
		let date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = '; expires=' + date.toUTCString()
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/'
}
function getCookie(name) {
	let nameEQ = name + '='
	let ca = document.cookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
	}
	return false
}

class IndexPage extends Component {
	state = {
		load: 0,
	}

	componentDidMount() {
		if (getCookie('loading-splash')) {
			this.t1 = setTimeout(() => {
				this.setState({ load: 4 })
			}, 1000)
			return
		}

		this.t1 = setTimeout(() => {
			this.setState({ load: 1 })
		}, 2500)

		this.t2 = setTimeout(() => {
			this.setState({ load: 2 })
		}, 3000)

		this.t3 = setTimeout(() => {
			this.setState({ load: 3 })
			setCookie('loading-splash', 'done', 365)
		}, 6000)
	}

	componentWillUnmount() {
		clearTimeout(this.t1)
		clearTimeout(this.t2)
		clearTimeout(this.t3)
	}

	renderBlock(param, el, i) {
		let block = {
			WordPressAcf_gallery: (el, i) => <SlickGallery {...el} key={i} />,
			WordPressAcf_video_content: (el, i) => (
				<VideoContent {...el} key={i} />
			),
			WordPressAcf_feature_content: (el, i) => (
				<FeatureContent {...el} key={i} />
			),
			WordPressAcf_latest_posts: (el, i) => (
				<LatestPosts {...el} key={i} />
			),
		}[param]

		if (!block) return

		return block(el, i)
	}

	render() {
		let { load } = this.state
		let home = this.props.data.wordpressPage
		let content = home.acf.content_block_page.slice()
		let landing = content.shift()

		return (
			<>
				<SEO
					title={he.decode(home.yoast_meta.yoast_wpseo_title)}
					bodyClass="home"
					description={home.yoast_meta.yoast_wpseo_metadesc}
				/>
				<Helmet>
					<script>
					{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','GTM-NRGD5G7M');`}
					</script>
				</Helmet>
				<PageCover>
					{this.renderBlock(landing.__typename, landing)}
				</PageCover>
				<div className="page-cover__under">
					{content &&
						content.map((el, i) => {
							return this.renderBlock(el.__typename, el, i)
						})}
					<Subscribe />
				</div>
			</>
		)
	}
}

export default IndexPage

export const homeQuery = graphql`
	query homeQuery {
		wordpressPage(wordpress_id: { eq: 2 }) {
			yoast_meta {
				yoast_wpseo_title
				yoast_wpseo_metadesc
			}
			title
			acf {
				page_cover
				content_block_page {
					__typename
					... on WordPressAcf_gallery {
						overlay_content
						slides {
							overlay_transparency
							overlay_colour
							type
							image {
								localFile {
									childImageSharp {
										original {
											src
										}
									}
								}
							}
							mobile_image {
								localFile {
									childImageSharp {
										original {
											src
										}
									}
								}
							}
							video_id
							html
							title
							link
						}
					}
					... on WordPressAcf_latest_posts {
						title
						content
					}

					... on WordPressAcf_video_content {
						text
						placeholder {
							alt_text
							localFile {
								childImageSharp {
									original {
										src
									}
								}
							}
						}
						video
						video_title

						image {
							alt_text
							localFile {
								childImageSharp {
									original {
										src
									}
								}
							}
						}
						paragraph_title
						paragraph
						link_text
						link_path
					}
					... on WordPressAcf_feature_content {
						image {
							alt_text
							localFile {
								childImageSharp {
									original {
										src
									}
								}
							}
						}
						paragraph_title
						paragraph
						link_text
						link_path
					}
				}
			}
		}
	}
`
