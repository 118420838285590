import React, { Component } from 'react'
import { Link } from 'gatsby'

import { Close } from './icons'

class VideoContent extends Component {
	state = {
		active: false,
	}

	renderImage(image) {
		if (image == null) {
			return
		}
		if (image === null || !image.localFile) return
		return (
			<img
				src={image.localFile.childImageSharp.original.src}
				alt={image.alt_text}
			/>
		)
	}

	render() {
		let {
			text,
			placeholder,
			video,
			video_title,
			image,
			paragraph_title,
			paragraph,
			link_text,
			link_path,
		} = this.props
		let { active } = this.state
		let additional = ''
		if (paragraph_title != '') {
			additional = (
				<div className="video-content__secondary container">
					<picture>{this.renderImage(image)}</picture>
					<div>
						{paragraph_title && <h2>{paragraph_title}</h2>}
						<p>{paragraph}</p>
						<Link to={link_path}>{link_text}</Link>
					</div>
				</div>
			)
		}
		return (
			<section className="video-content">
				{video && (
					<div className="video-content__primary container">
						<p>{text}</p>
						<picture
							onClick={() => this.setState({ active: true })}
						>
							{this.renderImage(placeholder)}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="73"
								height="74"
								viewBox="0 0 73 74"
							>
								<g transform="translate(-798 -1223)">
									<ellipse
										cx="36.5"
										cy="37"
										rx="36.5"
										ry="37"
										transform="translate(798 1223)"
										fill="#fff"
									/>
									<path
										d="M297.638,420.714V393.232a1.134,1.134,0,0,1,1.716-.973L322.3,406a1.134,1.134,0,0,1,0,1.946l-22.95,13.741A1.134,1.134,0,0,1,297.638,420.714Z"
										transform="translate(527.362 852.904)"
										fill="#fff"
									/>
								</g>
							</svg>
						</picture>
					</div>
				)}
				{additional}
				<div
					className={`video-content__modal ${active ? 'active' : ''}`}
				>
					<h3>{video_title}</h3>
					<div
						className="video-content__video"
						dangerouslySetInnerHTML={{ __html: active && video }}
					/>
					<span
						className="close"
						onClick={() => this.setState({ active: false })}
					>
						<Close colour="#737373" />
					</span>
				</div>
			</section>
		)
	}
}

export default VideoContent
